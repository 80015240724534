// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bfcm-jsx": () => import("./../../../src/pages/bfcm.jsx" /* webpackChunkName: "component---src-pages-bfcm-jsx" */),
  "component---src-pages-contact-sales-jsx": () => import("./../../../src/pages/contact-sales.jsx" /* webpackChunkName: "component---src-pages-contact-sales-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-features-js": () => import("./../../../src/pages/product/features.js" /* webpackChunkName: "component---src-pages-product-features-js" */),
  "component---src-pages-product-for-enterprise-js": () => import("./../../../src/pages/product/for-enterprise.js" /* webpackChunkName: "component---src-pages-product-for-enterprise-js" */),
  "component---src-pages-product-pricing-and-foreign-currency-rates-estimator-js": () => import("./../../../src/pages/product-pricing-and-foreign-currency-rates-estimator.js" /* webpackChunkName: "component---src-pages-product-pricing-and-foreign-currency-rates-estimator-js" */),
  "component---src-pages-product-why-pricelist-js": () => import("./../../../src/pages/product/why-pricelist.js" /* webpackChunkName: "component---src-pages-product-why-pricelist-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-sign-up-for-beta-js": () => import("./../../../src/pages/sign-up-for-beta.js" /* webpackChunkName: "component---src-pages-sign-up-for-beta-js" */),
  "component---src-templates-compare-all-js": () => import("./../../../src/templates/compare-all.js" /* webpackChunkName: "component---src-templates-compare-all-js" */),
  "component---src-templates-compare-one-js": () => import("./../../../src/templates/compare-one.js" /* webpackChunkName: "component---src-templates-compare-one-js" */),
  "component---src-templates-countries-all-js": () => import("./../../../src/templates/countries-all.js" /* webpackChunkName: "component---src-templates-countries-all-js" */),
  "component---src-templates-countries-one-js": () => import("./../../../src/templates/countries-one.js" /* webpackChunkName: "component---src-templates-countries-one-js" */),
  "component---src-templates-solution-one-js": () => import("./../../../src/templates/solution-one.js" /* webpackChunkName: "component---src-templates-solution-one-js" */),
  "component---src-templates-teams-all-js": () => import("./../../../src/templates/teams-all.js" /* webpackChunkName: "component---src-templates-teams-all-js" */),
  "component---src-templates-teams-one-js": () => import("./../../../src/templates/teams-one.js" /* webpackChunkName: "component---src-templates-teams-one-js" */)
}

