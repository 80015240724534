module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-XHFHXS9VMW","cookieName":"gatsby-gdpr","anonymize":true,"allowAdFeatures":false},"facebookPixel":{"pixelId":"1249888218823842","cookieName":"gatsby-gdpr"},"hotjar":{"hjid":"2680000","hjsv":"6","cookieName":"gatsby-gdpr"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pricelist","short_name":"Pricelist","start_url":"/","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d81924c35548b71d9ed26c6fca81f827"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
